import { JSX } from '@babel/types'
import React, { useState } from 'react'
import { PrimaryButton } from 'shared/components/button'
import CreateModal, { CreateModalProps } from 'shared/components/create-modal'
import { Loader } from 'shared/components/loader'
import { useListingFilter } from 'shared/hooks/use-listing-filter'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'
import FilterIcon from 'shared/icons/filter-icon'
import { DataWithPaginationInterface } from 'shared/types/pagination-interface'
import { CountInterface } from 'shared/types/pagination-interface'
import { twMerge } from 'tailwind-merge'
import { BaseTableProps } from './table/components/base-table'

export type FilterableItemType<T> = {
  [K in keyof T]: {
    field: K
    onRender: (
      value: T[K],
      onChange: (value: T[K], state?: T) => void,
      state: T,
      onChangeState: (state: T) => void,
    ) => JSX.Element
    shouldRender?: boolean
  }
}[keyof T]

export type FiltererType<K> = {
  filter: K
  setFilter: React.Dispatch<React.SetStateAction<K>>
  defaultFilters: K
  responseFilter?: K
  setResponseFilter?: React.Dispatch<React.SetStateAction<K>>
}

export interface CreateDataInterface<T>
  extends Omit<CreateModalProps<T>, 'afterLeave' | 'opened' | 'onClose'> {
  onOpen?: () => Promise<void>
  testAttributePostfix?: string
  disabled?: boolean
  onAfterLeave?: () => void
}

export interface FilterDataInterface<T, K>
  extends Partial<Pick<BaseTableProps<T>, 'paginator' | 'limiter'>> {
  filterable: FilterableItemType<K>[]
  filterer: FiltererType<K>
  testAttributePostfix?: string
}

export interface ListingLayoutProps<T extends DataWithPaginationInterface<T['items'][0]>, K, R> {
  data: T | undefined
  title: string | JSX.Element
  titleClassName?: string
  headerClassName?: string
  counter?: CountInterface | false
  type: 'side' | 'compact' | 'default' | 'large'
  children: JSX.Element
  customHeaderElement?: JSX.Element
  filterData?: FilterDataInterface<T, K>
  createData?: CreateDataInterface<R>
}

const ListingLayout = <T extends DataWithPaginationInterface<T['items'][0]>, K, R>({
  type,
  data,
  counter,
  title,
  titleClassName,
  headerClassName,
  filterData,
  createData,
  children,
  customHeaderElement,
}: ListingLayoutProps<T, K, R>) => (
  <>
    {type === 'compact' && (
      <CompactListingLayout
        data={data}
        counter={counter}
        title={title}
        titleClassName={titleClassName}
        headerClassName={headerClassName}
        filterData={filterData}
        createData={createData}
        customHeaderElement={customHeaderElement}
      >
        {children}
      </CompactListingLayout>
    )}
    {type === 'default' && (
      <DefaultListingLayout
        counter={counter}
        data={data}
        title={title}
        titleClassName={titleClassName}
        headerClassName={headerClassName}
        filterData={filterData}
        createData={createData}
        customHeaderElement={customHeaderElement}
      >
        {children}
      </DefaultListingLayout>
    )}
    {type === 'large' && (
      <LargeListingLayout
        counter={counter}
        data={data}
        title={title}
        titleClassName={titleClassName}
        headerClassName={headerClassName}
        filterData={filterData}
        createData={createData}
        customHeaderElement={customHeaderElement}
      >
        {children}
      </LargeListingLayout>
    )}
    {type === 'side' && (
      <SideListingLayout
        counter={counter}
        data={data}
        title={title}
        titleClassName={titleClassName}
        headerClassName={headerClassName}
        filterData={filterData}
        createData={createData}
        customHeaderElement={customHeaderElement}
      >
        {children}
      </SideListingLayout>
    )}
  </>
)

interface ChildListingLayoutProps<T extends DataWithPaginationInterface<T['items'][0]>, K, R>
  extends Pick<
    ListingLayoutProps<T, K, R>,
    | 'data'
    | 'filterData'
    | 'title'
    | 'titleClassName'
    | 'headerClassName'
    | 'createData'
    | 'children'
    | 'customHeaderElement'
    | 'counter'
  > {}

const CompactListingLayout = <T extends DataWithPaginationInterface<T['items'][0]>, K, R>({
  title,
  titleClassName,
  data,
  counter,
  filterData,
  createData,
  children,
  customHeaderElement,
}: ChildListingLayoutProps<T, K, R>) => {
  const { t } = useLocoTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isFilterShown,
    setIsFilterShown,
    handleFilter,
    setIsDefaultValuesFetching,
    isDefaultValuesFetching,
    tempState,
    setTempState,
  } = useListingFilter({ filterData })

  const WrappedTitle = () => {
    const className = `flex items-center whitespace-nowrap h-[42px] ${titleClassName || ''}`
    return typeof title === 'string' ? (
      <h1 className={className}>{title}</h1>
    ) : (
      <div className={className}>{title}</div>
    )
  }

  return (
    <>
      <div className={`flex mb-5 justify-between flex-col lg:flex-row gap-5 lg:gap-3`}>
        <div className={`flex justify-between flex-wrap lg:basis-full truncate`}>
          {data?.items.length === 0 || counter === false ? (
            <WrappedTitle />
          ) : (
            <div className={`flex gap-1 items-center`}>
              {counter ? (
                <h2
                  className={`whitespace-nowrap text-darkblue font-bold text-[22px] ${
                    titleClassName || ''
                  }`}
                >
                  {counter.count}
                </h2>
              ) : (
                <Loader type={'dark'} small />
              )}
              <WrappedTitle />
            </div>
          )}
          <div className="flex gap-3 ml-auto">
            {filterData && (
              <PrimaryButton
                onClick={() => setIsFilterShown(!isFilterShown)}
                className="items-center flex lg:hidden"
              >
                <FilterIcon className="w-[15px] h-[15px]" />
              </PrimaryButton>
            )}
            {customHeaderElement && (
              <div className={'flex justify-end lg:hidden'}>{customHeaderElement}</div>
            )}
            {createData && (
              <PrimaryButton
                disabled={!data}
                onClick={() => {
                  if (createData.onOpen) {
                    setIsDefaultValuesFetching(true)
                    createData.onOpen().then(() => {
                      setIsDefaultValuesFetching(false)
                      setIsModalOpen(true)
                    })
                  } else setIsModalOpen(true)
                }}
                isFetching={isDefaultValuesFetching}
                className="flex lg:hidden"
              >
                <AddIcon />
                <span className="font-bold hidden sm:flex"> {t('global.create')}</span>
              </PrimaryButton>
            )}
          </div>
        </div>
        {filterData && tempState && (
          <div
            className={`flex-col w-full rounded-lg overflow-x-hidden lg:overflow-visible ${
              isFilterShown ? 'flex' : 'hidden lg:flex'
            }`}
          >
            <div className={`bg-blue-100 justify-center py-4 px-6 flex lg:hidden`}>
              <span className="font-bold font-inter text-[17px] text-darkblue">
                {t('global.filter_by')}
              </span>
            </div>
            <form
              className={`py-4 lg:py-0 px-6 lg:px-0 rounded-b-lg gap-3 w-full bg-white lg:bg-transparent justify-end flex-col lg:flex-row ${
                isFilterShown ? 'flex' : 'hidden lg:flex'
              }`}
            >
              {filterData.filterable.map(
                el =>
                  el.shouldRender !== false &&
                  el.onRender(
                    tempState[el.field],
                    value => {
                      setTempState(prev => {
                        if (prev) {
                          return { ...prev, [el.field]: value }
                        }
                      })
                    },
                    tempState,
                    newTempState => setTempState(newTempState),
                  ),
              )}
              <PrimaryButton
                type="submit"
                onClick={e => {
                  e.preventDefault()
                  handleFilter()
                }}
                className="flex items-center min-w-[126px]"
                disabled={!data}
                testAttributePostfix={filterData.testAttributePostfix}
              >
                <FilterIcon className="w-[15px] h-[15px]" />
                <span className="font-bold"> {t('global.filter')}</span>
              </PrimaryButton>
            </form>
          </div>
        )}
        {customHeaderElement && (
          <div className={'hidden justify-end lg:flex'}>{customHeaderElement}</div>
        )}
        {createData && (
          <>
            <PrimaryButton
              disabled={createData.disabled ? createData.disabled : !data}
              onClick={() => {
                if (createData.onOpen) {
                  setIsDefaultValuesFetching(true)
                  createData.onOpen().then(() => {
                    setIsDefaultValuesFetching(false)
                    setIsModalOpen(true)
                  })
                } else setIsModalOpen(true)
              }}
              isFetching={isDefaultValuesFetching}
              className="hidden lg:flex"
              testAttributePostfix={createData.testAttributePostfix}
            >
              <AddIcon />
              <span className="font-bold"> {t('global.create')}</span>
            </PrimaryButton>
            <CreateModal
              opened={isModalOpen}
              onClose={() => {
                setIsModalOpen(false)
              }}
              afterLeave={createData.onAfterLeave}
              {...createData}
              onCreate={data => {
                if (filterData) {
                  setTempState(filterData.filterer.defaultFilters)
                  filterData.filterer.setFilter(filterData.filterer.defaultFilters)
                }
                return createData.onCreate(data)
              }}
            />
          </>
        )}
      </div>
      {children}
    </>
  )
}

const DefaultListingLayout = <T extends DataWithPaginationInterface<T['items'][0]>, K, R>({
  title,
  data,
  counter,
  filterData,
  createData,
  children,
  customHeaderElement,
  headerClassName,
}: ChildListingLayoutProps<T, K, R>) => {
  const { t } = useLocoTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isFilterShown,
    setIsFilterShown,
    handleFilter,
    setIsDefaultValuesFetching,
    isDefaultValuesFetching,
    tempState,
    setTempState,
  } = useListingFilter({ filterData })

  return (
    <>
      <div className={`flex mb-5 justify-between flex-col xl:flex-row gap-5 lg:gap-3`}>
        <div className={`flex justify-between gap-3 ${headerClassName}`}>
          {data?.items.length === 0 || counter === false ? (
            <h1 className="flex items-center whitespace-nowrap h-[42px]">{title}</h1>
          ) : (
            <div className={`flex gap-1 items-center`}>
              {counter ? (
                <h2 className={'whitespace-nowrap text-darkblue font-bold text-[22px]'}>
                  {counter.count}
                </h2>
              ) : (
                <Loader type={'dark'} small />
              )}
              <h1 className="flex items-center whitespace-nowrap h-[42px]">{title}</h1>
            </div>
          )}
          <div className="flex gap-3 flex-wrap">
            {filterData && (
              <PrimaryButton
                onClick={() => setIsFilterShown(!isFilterShown)}
                className="items-center flex xl:hidden"
              >
                <FilterIcon className="w-[15px] h-[15px]" />
              </PrimaryButton>
            )}
            {customHeaderElement && <div className={'flex xl:hidden'}>{customHeaderElement}</div>}
            {createData && (
              <PrimaryButton
                disabled={createData.disabled ? createData.disabled : !data}
                onClick={() => {
                  if (createData.onOpen) {
                    setIsDefaultValuesFetching(true)
                    createData.onOpen().then(() => {
                      setIsDefaultValuesFetching(false)
                      setIsModalOpen(true)
                    })
                  } else setIsModalOpen(true)
                }}
                isFetching={isDefaultValuesFetching}
                className="flex xl:hidden"
                testAttributePostfix={createData.testAttributePostfix}
              >
                <AddIcon />
                <span className="font-bold hidden sm:flex"> {t('global.create')}</span>
              </PrimaryButton>
            )}
          </div>
        </div>
        {filterData && tempState && (
          <div
            className={`flex-col w-full rounded-lg overflow-x-hidden lg:overflow-visible ${
              isFilterShown ? 'flex' : 'hidden xl:flex'
            }`}
          >
            <div className={`bg-blue-100 justify-center py-4 px-6 flex lg:hidden`}>
              <span className="font-bold font-inter text-[17px] text-darkblue">
                {t('global.filter_by')}
              </span>
            </div>
            <form
              className={`py-4 lg:py-0 px-6 lg:px-0 rounded-b-lg gap-3 w-full bg-white lg:bg-transparent justify-end flex-col lg:flex-row ${
                isFilterShown ? 'flex' : 'hidden lg:flex'
              }`}
            >
              {filterData.filterable.map(
                el =>
                  el.shouldRender !== false &&
                  el.onRender(
                    tempState[el.field],
                    value => {
                      setTempState(prev => {
                        if (prev) {
                          return { ...prev, [el.field]: value }
                        }
                      })
                    },
                    tempState,
                    newTempState => setTempState(newTempState),
                  ),
              )}
              <PrimaryButton
                type="submit"
                onClick={e => {
                  e.preventDefault()
                  handleFilter()
                }}
                className="flex items-center"
                disabled={!data}
                testAttributePostfix={filterData.testAttributePostfix}
              >
                <FilterIcon className="w-[15px] h-[15px]" />
                <span className="font-bold"> {t('global.filter')}</span>
              </PrimaryButton>
            </form>
          </div>
        )}
        {customHeaderElement && <div className={'hidden xl:flex'}>{customHeaderElement}</div>}
        {createData && (
          <>
            <PrimaryButton
              disabled={!data}
              onClick={() => {
                if (createData.onOpen) {
                  setIsDefaultValuesFetching(true)
                  createData.onOpen().then(() => {
                    setIsDefaultValuesFetching(false)
                    setIsModalOpen(true)
                  })
                } else setIsModalOpen(true)
              }}
              isFetching={isDefaultValuesFetching}
              className="hidden xl:flex"
            >
              <AddIcon />
              <span className="font-bold"> {t('global.create')}</span>
            </PrimaryButton>
            <CreateModal
              caption={createData.caption}
              opened={isModalOpen}
              requiredFields={createData.requiredFields}
              onClose={() => {
                setIsModalOpen(false)
              }}
              afterLeave={createData.onAfterLeave}
              defaultValues={createData.defaultValues}
              creatable={createData.creatable}
              onCreate={data => createData.onCreate(data)}
            />
          </>
        )}
      </div>
      {children}
    </>
  )
}

const SideListingLayout = <T extends DataWithPaginationInterface<T['items'][0]>, K, R>({
  title,
  data,
  counter,
  filterData,
  createData,
  children,
  customHeaderElement,
  headerClassName,
}: ChildListingLayoutProps<T, K, R>) => {
  const { t } = useLocoTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isFilterShown,
    setIsFilterShown,
    handleFilter,
    setIsDefaultValuesFetching,
    isDefaultValuesFetching,
    tempState,
    setTempState,
  } = useListingFilter({ filterData })

  return (
    <>
      <div className={`flex mb-5 justify-between`}>
        {data?.items.length === 0 || counter === false ? (
          <h1 className="flex items-center whitespace-nowrap h-[42px]">{title}</h1>
        ) : (
          <div className={twMerge('flex gap-1 items-center', headerClassName)}>
            {counter ? (
              <h2 className={'whitespace-nowrap text-darkblue font-bold text-[22px]'}>
                {counter.count}
              </h2>
            ) : (
              <Loader type={'dark'} small />
            )}
            <h1 className="flex items-center whitespace-nowrap h-[42px]">{title}</h1>
          </div>
        )}
        <div className="flex gap-5 md:gap-3">
          {filterData && (
            <PrimaryButton
              onClick={() => setIsFilterShown(!isFilterShown)}
              className="items-center flex xl:hidden"
            >
              <FilterIcon className="w-[15px] h-[15px]" />
            </PrimaryButton>
          )}
          {customHeaderElement && <div>{customHeaderElement}</div>}
          {createData && (
            <>
              <PrimaryButton
                disabled={createData.disabled ? createData.disabled : !data}
                onClick={() => {
                  if (createData.onOpen) {
                    setIsDefaultValuesFetching(true)
                    createData.onOpen().then(() => {
                      setIsDefaultValuesFetching(false)
                      setIsModalOpen(true)
                    })
                  } else setIsModalOpen(true)
                }}
                isFetching={isDefaultValuesFetching}
                testAttributePostfix={createData.testAttributePostfix}
              >
                <AddIcon />
                <span className="font-bold hidden sm:flex"> {t('global.create')}</span>
              </PrimaryButton>
              <CreateModal
                caption={createData.caption}
                opened={isModalOpen}
                requiredFields={createData.requiredFields}
                onClose={() => {
                  setIsModalOpen(false)
                }}
                afterLeave={createData.onAfterLeave}
                defaultValues={createData.defaultValues}
                creatable={createData.creatable}
                onCreate={data => createData.onCreate(data)}
              />
            </>
          )}
        </div>
      </div>
      {filterData && tempState && (
        <div className={`flex gap-0 xl:gap-5 flex-col xl:flex-row`}>
          <div
            className={`flex flex-col w-full xl:w-[300px] rounded-lg overflow-x-hidden lg:overflow-visible xl:overflow-x-hidden`}
          >
            <div
              className={`bg-blue-100 justify-center py-4 px-6 ${
                isFilterShown ? 'flex lg:hidden xl:flex' : 'hidden xl:flex'
              }`}
            >
              <span className="font-bold font-inter text-[17px] text-darkblue">
                {t('global.filter_by')}
              </span>
            </div>
            <form
              className={twMerge(
                'flex flex-col flex-wrap justify-start lg:flex-row xl:flex-col xl:flex-nowrap xl:justify-end py-4 lg:py-0 xl:py-4 px-6 lg:px-0 xl:px-2 rounded-b-lg gap-3 w-full bg-white lg:bg-transparent xl:bg-white mb-5 xl:mb-0',
                isFilterShown ? 'flex' : 'hidden xl:flex',
              )}
            >
              {filterData.filterable.map(
                el =>
                  el.shouldRender !== false &&
                  el.onRender(
                    tempState[el.field],
                    value => {
                      setTempState(prev => {
                        if (prev) {
                          return { ...prev, [el.field]: value }
                        }
                      })
                    },
                    tempState,
                    newTempState => setTempState(newTempState),
                  ),
              )}
              <PrimaryButton
                type="submit"
                onClick={e => {
                  e.preventDefault()
                  handleFilter()
                }}
                className="flex items-center"
                disabled={!data}
                testAttributePostfix={filterData.testAttributePostfix}
              >
                <FilterIcon className="w-[15px] h-[15px]" />
                <span className="font-bold"> {t('global.filter')}</span>
              </PrimaryButton>
            </form>
          </div>
          <div className={'xl:w-[calc(100%-300px-20px)]'}>{children}</div>
        </div>
      )}
      {!filterData && children}
    </>
  )
}

const LargeListingLayout = <T extends DataWithPaginationInterface<T['items'][0]>, K, R>({
  title,
  data,
  counter,
  filterData,
  createData,
  children,
  customHeaderElement,
}: ChildListingLayoutProps<T, K, R>) => {
  const { t } = useLocoTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    isFilterShown,
    setIsFilterShown,
    handleFilter,
    setIsDefaultValuesFetching,
    isDefaultValuesFetching,
    tempState,
    setTempState,
  } = useListingFilter({ filterData })

  return (
    <>
      <div className={`flex mb-5 justify-between`}>
        {data?.items.length === 0 || counter === false ? (
          <h1 className="flex items-center whitespace-nowrap h-[42px]">{title}</h1>
        ) : (
          <div className={`flex gap-1 items-center`}>
            {counter ? (
              <h2 className={'whitespace-nowrap text-darkblue font-bold text-[22px]'}>
                {counter.count}
              </h2>
            ) : (
              <Loader type={'dark'} small />
            )}
            <h1 className="flex items-center whitespace-nowrap h-[42px]">{title}</h1>
          </div>
        )}
        <div className="flex gap-5 md:gap-3">
          {filterData && (
            <PrimaryButton
              onClick={() => setIsFilterShown(!isFilterShown)}
              className="items-center flex lg:hidden"
              testAttributePostfix={filterData.testAttributePostfix}
            >
              <FilterIcon className="w-[15px] h-[15px]" />
            </PrimaryButton>
          )}
          {customHeaderElement && <div>{customHeaderElement}</div>}
          {createData && (
            <>
              <PrimaryButton
                disabled={!data}
                onClick={() => {
                  if (createData.onOpen) {
                    setIsDefaultValuesFetching(true)
                    createData.onOpen().then(() => {
                      setIsDefaultValuesFetching(false)
                      setIsModalOpen(true)
                    })
                  } else setIsModalOpen(true)
                }}
                isFetching={isDefaultValuesFetching}
                testAttributePostfix={createData.testAttributePostfix}
              >
                <AddIcon />
                <span className="font-bold hidden sm:flex"> {t('global.create')}</span>
              </PrimaryButton>
              <CreateModal
                caption={createData.caption}
                opened={isModalOpen}
                requiredFields={createData.requiredFields}
                onClose={() => {
                  setIsModalOpen(false)
                }}
                afterLeave={createData.onAfterLeave}
                defaultValues={createData.defaultValues}
                creatable={createData.creatable}
                onCreate={data => {
                  setTempState(undefined!)
                  return createData.onCreate(data)
                }}
              />
            </>
          )}
        </div>
      </div>
      {filterData && tempState && (
        <div className={`flex flex-col w-full rounded-lg overflow-x-hidden lg:overflow-visible`}>
          <div
            className={twMerge(
              'bg-blue-100 justify-center py-4 px-6',
              isFilterShown ? 'flex lg:hidden' : 'hidden',
            )}
          >
            <span className="font-bold font-inter text-[17px] text-darkblue">
              {t('global.filter_by')}
            </span>
          </div>
          <form
            className={twMerge(
              'flex py-4  lg:py-0 px-6 lg:px-0 rounded-b-lg gap-3 w-full bg-white lg:bg-transparent justify-end flex-col lg:flex-row mb-5',
              isFilterShown ? 'flex' : 'hidden lg:flex',
            )}
          >
            {filterData.filterable.map(
              el =>
                el.shouldRender !== false &&
                el.onRender(
                  tempState[el.field],
                  value => {
                    setTempState(prev => {
                      if (prev) {
                        return { ...prev, [el.field]: value }
                      }
                    })
                  },
                  tempState,
                  newTempState => setTempState(newTempState),
                ),
            )}
            <PrimaryButton
              type="submit"
              onClick={e => {
                e.preventDefault()
                handleFilter()
              }}
              className="flex items-center"
              disabled={!data}
            >
              <FilterIcon className="w-[15px] h-[15px]" />
              <span className="font-bold"> {t('global.filter')}</span>
            </PrimaryButton>
          </form>
        </div>
      )}
      {children}
    </>
  )
}

export default ListingLayout
